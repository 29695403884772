import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { IMainNavBar } from '../../interfaces/tcc-main-nav-interface';
import { TccStorageService } from '../../services/tcc-storage.service';

@Component({
  selector: 'tcc-main-navbar',
  templateUrl: './tcc-main-navbar.component.html',
  styleUrls: ['./tcc-main-navbar.component.scss'],
})
export class TccMainNavbarComponent implements OnInit {

  @Input() headermenuitems: IMainNavBar;
  isCustomTheme: boolean;
  themeColors;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 960px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly tccStorageService: TccStorageService
  ) { }

  ngOnInit() {
    this.tccStorageService.customThemeSet.subscribe((val: boolean) => {
      this.isCustomTheme = val;
    });
    this.tccStorageService.themeColors.subscribe((val) => {
      this.themeColors = val;
    });
  }

}
