import { Component, Input, ViewChild } from '@angular/core';
import { IMainNavBar } from '../../../interfaces/tcc-main-nav-interface';

@Component({
  selector: 'tcc-menu-item',
  templateUrl: './tcc-menu-item.component.html',
  styleUrls: ['./tcc-menu-item.component.css']
})
export class TccMenuItemComponent {

  @Input() items: IMainNavBar[];
  @Input() themeColors;
  @Input() isCustomTheme: boolean;
  @ViewChild('childMenu', {static: true}) public childMenu;

  constructor() { }

}
