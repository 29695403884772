// Angular
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

// rxjs
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';

// Services
import { TranslateService } from '@ngx-translate/core';

// constants
import { BANNER_BLUE, LOGO_ONETRUST_WHITE } from './../../constants/assets.constants';
import { LANGUAGE_DEFAULT, LANGUAGES } from './../../constants/i18n.constants';
import { APP_TITLE } from '../../constants/metadata.constants';

@Injectable({
  providedIn: 'root',
})
export class AppMetaService {
  private readonly banner$ = new BehaviorSubject(BANNER_BLUE);
  private readonly logo$ = new BehaviorSubject(LOGO_ONETRUST_WHITE);
  private readonly title$ = new BehaviorSubject(APP_TITLE);
  private readonly defaultLanguage$ = new BehaviorSubject(LANGUAGE_DEFAULT);

  constructor(private readonly titleService: Title, private readonly translate: TranslateService) {}

  getBanner(): Observable<string> {
    return this.banner$.asObservable();
  }

  getLogo(): Observable<string> {
    return this.logo$.asObservable();
  }

  handleAppMetaLifecyle(): Observable<boolean> {
    return combineLatest([this.defaultLanguage$, this.title$]).pipe(
      switchMap(([lang, title]) => {
        return this.setupTranslations(lang).pipe(mapTo(title));
      }),
      switchMap((title) => {
        return this.translate.stream(title);
      }),
      tap((title: string) => {
        this.setTitle(title);
      }),
      mapTo(true)
    );
  }

  private setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  private setupTranslations(lang: string): Observable<string> {
    this.translate.addLangs(LANGUAGES);
    this.translate.setDefaultLang(lang);
    return this.translate.use(lang);
  }
}
