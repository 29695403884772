<div
  class="wrapper__content slds-grid slds-grow slds-scrollable--none"
  [ngClass]="currentTheme === 'Light' ? 'branding-light-theme' : currentTheme === 'Dark' ? 'branding-dark-theme' : ''"
>
  <tcc-main-navbar
    [headermenuitems]="mainNavBarSchema"
  >
    <router-outlet></router-outlet>
  </tcc-main-navbar>
</div>
