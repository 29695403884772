<div>
  <mat-toolbar
    color="primary"
  >
    <mat-toolbar-row [ngStyle]='isCustomTheme ? {"background": themeColors?.menuColor?.value, "color": themeColors?.menuTextColor?.value } : null'>
      <button *ngIf="(isHandset$ | async)" mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-icon>{{ headermenuitems?.icon }}</mat-icon>
      <span>{{ headermenuitems?.title }}</span>
      <div class="menu-items" *ngIf="(isHandset$ | async) === false">
        <ng-container *ngFor="let item of headermenuitems?.menuItems">
          <span *ngIf="item?.subMenuItems && item?.subMenuItems?.length > 0">
            <a
              mat-button
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu.childMenu"
              [disabled]="item?.disabled"
            >
              <mat-icon>{{item?.icon}}</mat-icon>
              <span>{{item?.label}}</span>
              <mat-icon>expand_more</mat-icon>
            </a>
            <tcc-menu-item
              #menu
              [items]="item?.subMenuItems"
              [isCustomTheme]="isCustomTheme"
            ></tcc-menu-item>
          </span>
          <a
            *ngIf="!item?.subMenuItems"
            mat-button
            [attr.href]="item?.route"
            [disabled]="item?.disabled"
          >
            <mat-icon>{{item?.icon}}</mat-icon>
            <span>{{item?.label}}</span>
          </a>
        </ng-container>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container>
    <mat-sidenav
      #sidenav
      mode="over"
      fixedInViewport="true"
      fixedTopGap="64"
      [ngStyle]='isCustomTheme ? {"background-color": themeColors?.menuColor?.value } : null'
    >
      <ng-container>
        <mat-nav-list
          *ngFor="let item of headermenuitems?.menuItems"
        >
          <span *ngIf="item?.subMenuItems && item?.subMenuItems?.length > 0">
            <mat-list-item
              href="#"
              [matMenuTriggerFor]="menu.childMenu"
              [disabled]="item?.disabled"
              [ngStyle]='isCustomTheme ? {"color": themeColors?.menuTextColor?.value } : null'
            >
              <mat-icon>{{item?.icon}}</mat-icon>
              <span>{{item?.label}}</span>
              <mat-icon>expand_more</mat-icon>
            </mat-list-item>
            <tcc-menu-item #menu [items]="item?.subMenuItems"></tcc-menu-item>
          </span>
          <mat-list-item
            *ngIf="!item?.subMenuItems"
            [attr.href]="item?.route"
            [disabled]="item?.disabled"
            [ngStyle]='isCustomTheme ? {"color": themeColors?.menuTextColor?.value } : null'
          >
            <mat-icon>{{item?.icon}}</mat-icon>
            <span>{{item?.label}}</span>
          </mat-list-item>
        </mat-nav-list>
      </ng-container>
    </mat-sidenav>
    <mat-sidenav-content
      class="sidenav-content"
      [ngStyle]='isCustomTheme ? {"background-color": themeColors?.backgroundColor?.value } : null'
    >
      <ng-content #maincontent></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>