import { IMainNavBar } from '../interfaces/tcc-main-nav-interface';

export const MainNavBarSchema: IMainNavBar = {
  controlType: 'Header',
  id: 'TcNavMenu',
  title: 'Trust Center',
  icon: 'admin_panel_settings',
  brandingImage: '',
  menuItems: [
    {
      id: 'TcNavMenuOverview',
      label: 'Overview',
      icon: 'dashboard',
      route: '/home'
    },
    {
      id: 'TcNavMenuPrefrences',
      label: 'Preferences',
      icon: 'fact_check',
      subMenuItems: [
        {
          id: 'TcNavMenuPrefrencesConsent',
          label: 'Consent',
          icon: 'check_circle',
          subMenuItems: [
            {
              id: 'TcNavMenuPrefrenceCenter',
              label: 'Preference Center',
              icon: 'assignment',
              route: '/home'
            },
            {
              id: 'TcNavMenuPrefrencesCookies',
              label: 'Privacy',
              icon: 'privacy_tip',
              route: '/home'
            },
          ]
        }
      ]
    },
    {
      id: 'TcNavMenuPrivacyRequests',
      label: 'Privacy Requests',
      icon: 'privacy_tip',
      subMenuItems: [
        {
          id: 'TcNavMenuPrefrencesRights',
          label: 'Rights',
          icon: 'task_alt',
          route: '/home'
        },
        {
          id: 'TcNavMenuPrefrencesRequests',
          label: 'Requests',
          icon: 'lock_open',
          route: '/home'
        },
      ]
    },
    {
      id: 'TcNavMenuPoliciesAndTerms',
      label: 'Policies and Terms',
      icon: 'article',
      route: '/home'
    },
    {
      id: 'TcNavMenuCommunication',
      label: 'Communication',
      icon: 'manage_accounts',
      route: '/home'
    },
  ]
};
