import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TccStorageService {

  currentTheme = new BehaviorSubject<string>('Default');
  customThemeSet = new BehaviorSubject<boolean>(true);
  themeColors = new BehaviorSubject<unknown>(null);
  constructor() { }

}
