<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">
    <!-- Handle branch node menu items -->
    <span *ngIf="child?.subMenuItems && child?.subMenuItems?.length > 0">
      <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu">
        <mat-icon>{{child?.icon}}</mat-icon>
        <span>{{child?.label}}</span>
      </button>
      <tcc-menu-item #menu [items]="child?.subMenuItems"></tcc-menu-item>
    </span>
    <!-- Handle leaf node menu items -->
    <span *ngIf="!child?.subMenuItems || child?.subMenuItems?.length === 0">
      <button mat-menu-item routerLink="/{{child?.route}}" routerLinkActive="active">
        <mat-icon>{{child?.icon}}</mat-icon>
        <span>{{child?.label}}</span>
      </button>
    </span>
  </span>
</mat-menu>
