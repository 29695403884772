import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivationStart, Event, NavigationEnd, NavigationError, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'trust-center-consumer-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly loading$ = new BehaviorSubject<boolean>(true);

  private readonly destroy$ = new Subject();

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.handleLoading();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  /**
   * Subscribe to router events to enable / disable the loading spinner.
   */
  private handleLoading(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: Event) => {
      const loadingState = this.getLoadingState(event);
      if (loadingState !== null) {
        this.loading$.next(loadingState);
      }
    });
    this.removeIndexLoading();
  }

  /**
   * Remove the initial loading spinner defined in the index.
   */
  private removeIndexLoading(): void {
    const indexLoadingEl = document.querySelector('.initial-app-loading');
    if (indexLoadingEl && indexLoadingEl.parentNode) {
      indexLoadingEl.parentNode.removeChild(indexLoadingEl);
    }
  }

  /*
   * Return `true` when navigations start from an non-"Auth"-based route.
   * Return `false` when navigations end.
   */
  private getLoadingState(event: Event): boolean | null {
    if (event instanceof ActivationStart && location.pathname.indexOf('/auth') === -1) {
      return true;
    }

    if (event instanceof NavigationEnd || event instanceof NavigationError) {
      return false;
    }

    return null;
  }
}
