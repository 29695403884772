// Angular
import { Component, HostBinding, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { IMainNavBar } from '../../interfaces/tcc-main-nav-interface';

// services
import { AppMetaService } from './../../core/services/app-meta/app-meta.service';
import { TccStorageService } from '../../services/tcc-storage.service';
import { TccApiService } from '../../services/tcc-api.service';

// schema
import { MainNavBarSchema } from '../../schema/tcc-main-nav-schema';

@Component({
  selector: 'tcc-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit, OnDestroy {
  @HostBinding('class') layout = 'wrapper slds-grid slds-grid--vertical slds-grow';
  mainNavBarSchema: IMainNavBar = MainNavBarSchema;
  banner$ = this.appMetaService.getBanner().pipe(map((value) => `url("${value}")`));
  logo$ = this.appMetaService.getLogo();
  currentTheme: string;

  private readonly destroy$ = new Subject();

  constructor(
    private readonly appMetaService: AppMetaService,
    private readonly tccStorageService: TccStorageService,
    private readonly tccApiService: TccApiService,
  ) {}

  ngOnInit(): void {
    this.appMetaService.handleAppMetaLifecyle().pipe(takeUntil(this.destroy$)).subscribe();
    this.tccStorageService.currentTheme.subscribe((theme: string) => {
      this.currentTheme = theme;
    });
    this.tccStorageService.customThemeSet.next(true);
    this.getCustomThemeStyles();
  }

  private getCustomThemeStyles() {
    const tenantId = 3219;
    this.tccApiService.getCustomThemesStyles(tenantId).pipe(takeUntil(this.destroy$))
      .subscribe((customThemeResponse) => {
        this.tccStorageService.themeColors.next(customThemeResponse);
      }, (error) => {
        console.log(error);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
